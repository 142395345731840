globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"32a9bd879296d0e7bc322688a941f4c58c50e45f"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

Sentry.init({
  dsn: 'https://7ac9fbec7fecc5d636f2f276ab36df06@o4507088399761408.ingest.de.sentry.io/4507544678694992',

  tracesSampleRate: 0.1, // Only 10% of traces will be sent
  profilesSampleRate: 0.1, // Only 10% of profiles will be sent
  debug: false,
  replaysSessionSampleRate: 0, // 5% of sessions
  replaysOnErrorSampleRate: isProd ? 1.0 : 0, // Replays for all errors
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],

  beforeSend(event, hint) {
    if (!isProd) {
      return null; // Ignore events in non-production environments
    }

    const error = hint.originalException;
    if (error instanceof Error) {
      if (error.name === 'AbortError' && error.message.includes('Fetch is aborted')) {
        return null; // Ignore aborted fetch errors
      }
    }

    return event;
  },

  ignoreErrors: [
    'Network request failed',
    'Failed to fetch',
    'Loading chunk \\d+ failed', // Ignore chunk load failures
    'Script error.', // Ignore script errors from cross-origin scripts
    'Script loading failed', // Ignore script loading errors
    'Failed to load resource', // Catch network resource loading failures
    'ResizeObserver loop limit exceeded',
    'AbortError',
  ],
});
